@import "variables.scss";

body {
  background-color: $screen-background !important;
  top: 0 !important;
}

* {
  margin: 0;
  padding: 0;
}

ul {
  margin-bottom: 0;

  li {
    list-style: none;
  }
}

p {
  margin-bottom: 0;
}

a {
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: $primary-color;
  }
}

.main-container {
  padding-top: 0px;

  &.secured {
    padding-top: 40px;
  }

  &.no-header {
    padding-top: 0;
  }
}

.container {
  width: 85%;
  margin: auto;
}

.ant-btn {
  height: 44px;
  padding: 0 30px;
  font-size: 14px;
  border-radius: 40px;
  cursor: pointer;
  font-weight: 700;

  &.primary-btn {
    background: $primary-color;
    color: #fff;
    border: none;
  }

  &.primary-light-btn {
    background: $primary-color;
    color: #fff;
    border: none;
  }

  &.primary-btn-text {
    border: 1px solid $primary-color;
    color: $primary-color;

    &:hover {
      border-color: $primary-color !important;
    }
  }

}
