@import '../../styles/variables';

$header-height: 60px;

.home {
  background: $screen-background;

  .header {
    height: $header-height;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 99;
    padding: 0;
    line-height: inherit;
    background-color: #785fcd;

    .container {
      height: $header-height;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .logo {
      color: #fff;
      text-transform: uppercase;
      font-size: 16px;
    }

    .count {
      display: none;
    }

    .actions {
      button {
        margin-left: 10px;
      }
    }
  }

  .content {
    height: 100vh;
    padding: 80px 0 20px 0;

    .filters {
      margin-bottom: 20px;
      text-align: right;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .count {
        font-weight: bold;
      }

      .actions {
        display: none;
      }

      .ant-select {
        margin-left: 10px;

        .ant-select-selector {
          height: 44px;
          padding: 0 30px;
          font-size: 14px;
          border-radius: 40px;
          cursor: pointer;
          font-weight: 700;

          .ant-select-selection-item {
            line-height: 40px;
          }

          .ant-select-selection-placeholder {
            line-height: 40px;
          }
        }

        &:hover {
          .ant-select-selector {
            border: 1px solid $border-color;
          }
        }
      }
    }

    .table {
      .ant-table-thead > tr > th {
        background: #f9fbfe;
        padding: 8px 16px;
      }

      .ant-table-tbody > tr > td {
        padding: 8px 16px;
      }

      .ant-pagination-item:hover {
        border-color: $primary-color;

        a {
          color: $primary-color;
        }
      }

      .ant-pagination-item-active {
        border-color: $primary-color;

        a {
          color: $primary-color;
        }
      }

      .ant-table-body {
        .ant-table-row {
          &:nth-child(odd) {
            background: #f9fbfe;
          }

          .ant-table-cell {
            button {
              width: 32px;
              height: 32px;
              padding: 0;
              background: $primary-color;
              border: 1px solid $primary-color;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .container {
    width: 95%;
  }

  .table {
    width: 100%;
    overflow-y: scroll;

    .ant-table-wrapper {
      .ant-table {
        width: 1000px;
      }
    }
  }
}

@media (max-width: 520px) {
  .home {
    .header {
      .actions {
        display: none;
      }

      .count {
        display: block;
        font-size: 13px;
        font-weight: bold;
        color: #fff;
      }
    }

    .content {
      .filters {
        display: block;

        .left {
          display: none;
        }

        .actions {
          display: block;

          button {
            margin-left: 10px;
            margin-bottom: 10px;
            padding: 0 14px;
          }
        }

        .ant-select {
          margin-bottom: 10px;

          .ant-select-selector {
            padding: 0 20px;
          }
        }
      }
    }
  }
}
